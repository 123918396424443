
@keyframes backdrop {
    0% {
        filter: hue-rotate(0deg) brightness(70%);
        background-position: 0% 0%;

    }
    100% {
        filter: hue-rotate(90deg) brightness(80%);
        background-position: 100% 100%;
    }
}

.backdrop {

    position: fixed;
    z-index: -5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: black;
    background-image: url("backdrop.jpg");
    background-size: cover;
    animation-name: backdrop;
    animation-iteration-count: infinite;
    animation-duration: 30s;
    animation-timing-function: linear;
    animation-direction: alternate;


}
