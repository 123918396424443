

:root {
    --test: red;
}

.global {
    --fontHeavy: 700;
    --fontLight: 500;
}

.global_desktop {
    composes: global;
    --padding: 50px;
    --paddingTop: 75px;
    --fontHead: 28px;
    --fontSub: 16px;
    --width: 40%;

}

.global_mobile {
    composes: global;
    --padding: 25px;
    --paddingTop: 25px;
    --fontHead: 24px;
    --fontSub: 16px;
    --width: 100%;
}


.display {
    height: 30%;
    width: var(--width);    
    display: grid;
    grid-template-rows: var(--paddingTop) 1fr;
    grid-template-columns: var(--padding) 75px 1fr var(--padding);
    grid-template-areas: ". . . ." ". button message .";
}

.display_button {
    grid-area: button;
    margin-right: 25px;
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: black;
    border: 2px solid white;
    background-size: cover;

}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }

}

.display_button_loading {
    composes: display_button;
    animation-name: spin;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    background-image: url("icons/loading.png");

}

.display_button_pause {
    composes: display_button;
    background-image: url("icons/pause.png");

}

.display_button_error {
    composes: display_button;
    background-image: url("icons/error.png");
}

.display_button_lines {
    cursor: pointer;
    composes: display_button;
    background-image: url("icons/lines.png");
}

.display_message {
    grid-area: message;
    overflow: hidden;
}

.display_message_sub {
    margin-bottom: 7px;
    font-size: var(--fontSub);
    font-weight: var(--fontHeavy);
}

.display_message_head {
    margin-bottom: 7px;
    font-size: var(--fontHead);
    font-weight: var(--fontHeavy);
}

@keyframes message_fade_in {
    from {
        font-size: var(--fontHead);
    }
    to {
        font-size: var(--fontSub);
    }
}

@keyframes message_fade_out {
    from {
        font-size: var(--fontSub);
    }
    to {
        font-size: var(--fontHead);
    }
}

.message_fade_in {
    composes: display_message_head;
    animation-name: message_fade_in;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.message_fade_out {
    composes: display_message_head;
    animation-name: message_fade_out;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}


.options {
    box-sizing: border-box;
    width: var(--width);
    padding: var(--padding);
    font-size: var(--fontSub);
    font-weight: var(--fontLight);
    transform: translate(-100%,0);

}

.options_sep {
    margin-bottom: 20px;
    font-weight: 700;
    margin-top: var(--padding);
}


.box {
    position:fixed;
    width: var(--width);
    height: 100vh;
    background-color:black;
    z-index: -1;
    transform: translate(-100%,0);
}

@keyframes fade_in {
    from {
        transform: translate(-100%,0);
    }
    to {
        transform: translate(0,0);
    }
}

@keyframes fade_out {
    from {
        transform: translate(0,0);
    }    
    to {
        transform: translate(-100%,0);
    }
}

.box_fade_in {
    composes: box;
    animation-name: fade_in;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;

}

.box_fade_out {
    composes: box;
    animation-name: fade_out;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.options_fade_in {
    composes: options;
    animation-name: fade_in;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.options_fade_out {
    composes: options;
    animation-name: fade_out;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}


