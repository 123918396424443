

.container {

    z-index: -2;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;


}

.canvas {
    position: absolute;
}

.canvas_mobile {
    composes: canvas;
    height: 100vh;
    --offset: calc(-50vh + 50vw);
    transform: translate(var(--offset), 0)

}

.canvas_desktop {
    composes: canvas;
    width: 100%;
    --offset: calc(-50vw + 50vh);
    transform: translate(0, var(--offset))

}