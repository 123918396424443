.item {

    display: grid;
    grid-template-columns: 80px 1fr;
    grid-template-rows: auto;
    grid-template-areas: "cover info";
    margin-bottom: 10px;
    border: 2px solid #222222;
    padding: 10px;
    cursor: pointer;

}

.item_active {

    composes: item;
    border: 1px solid white;

}

.item:hover {
    border: 2px solid white;
}

.cover {
    grid-area: cover;
    width: 80px;
    height: 80px;

}

.info {

    grid-area: info;
    padding-left: 20px;


}

.title {
    font-weight: 700;
    margin-bottom: 10px;

}

.description {
    font-weight: 500;
    color: grey;

}