.item {

    display: grid;
    grid-template-columns: 80px 1fr;
    grid-template-rows: auto;
    grid-template-areas: "cover info";
    margin-bottom: 15px;
    padding: 10px;

}

.cover {
    grid-area: cover;
    width: 80px;
    height: 80px;

}

.info {

    grid-area: info;
    padding-left: 20px;

}

.title {
    font-weight: 700;
    font-size: 28px;
    margin-bottom: 10px;
}

.sub {
    font-weight: 700;
    margin-bottom: 15px; 
}

.description {
    font-weight: 500;
    max-width: 400px;
    color: grey;

}