
.global {
    word-break: break-word;
}

a {
    text-decoration: none;
}

.global_desktop {
    composes: global;
    --padding: 50px;
    --paddingTop: 75px;
    --fontHead: 28px;
    --fontSub: 16px;

    --titleSize: 100px;
    --titleMargin: 25px;

}

.global_mobile {
    composes: global;
    --padding: 25px;
    --paddingTop: 25px;
    --fontHead: 24px;
    --fontSub: 16px;

    --titleSize: 60px;
    --titleMargin: 15px;
}

.title {

    padding: var(--padding);
    padding-top: var(--paddingTop);
    font-weight: 700;
    font-size: var(--titleSize);
    margin-bottom: var(--titleMargin);

}


.box {
    padding: var(--padding);
    background-color: black;
}

.pseudo_box {
    height: 100vh;
    width: 100%;
    position: fixed;
    background-color: black;
}

.button_box {
    display: flex;
    justify-content: left;
}

.intro {
    margin-bottom: var(--titleMargin);
    color: grey;
}

.button {

    background-color: black;
    border: 2px solid white;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 25px;
    color: white;
    font-weight: 700;
    font-size: var(--fontHead);

}